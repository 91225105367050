import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import PassOpenQuestionComponent from './pass-open-question-component';
import PassClosedQuestionComponent from './pass-closed-question-component';
import { setCurrentQuestion, setQuestions } from '../../global-state/actions/exam-actions';
import { useParams } from 'react-router-dom';
interface Params {
    id: any
}
const PassExamComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [questionsList, setQuestionsList] = useState(props?.listOfQuestions);
    const [currentQuestion, setCurrentQuestion] = useState<any>();
    const [index, setIndex] = useState<number>(0);
    const { id } = useParams<Params>();
    const [idProgression, setIdProgression] = useState(props?.currentCourse?.exams?.find((obj: any) => obj?.id === Number(id))?.progression?.id)

    useEffect(() => {
        setIdProgression(props?.currentCourse?.exams?.find((obj: any) => obj?.id === Number(id))?.progression?.id)
    }, [])
    useEffect(() => {
        setIdProgression(props?.currentCourse?.exams?.find((obj: any) => obj?.id === Number(id))?.progression?.id)
    }, [props])

    useEffect(() => {
        setIndex(props?.indexQuestionToPass)
        if (props?.listOfQuestions && props?.listOfQuestions[props?.indexQuestionToPass]) {
            setCurrentQuestion(props?.listOfQuestions[props?.indexQuestionToPass])
        }
        setQuestionsList(props?.listOfQuestions);
    }, [props?.indexQuestionToPass, props?.listOfQuestions])

    return (

        <div style={props.isMobile ? { margin: "24px 12px 32px 12px" }: {margin: "24px 120px 32px 120px"}}>
            {currentQuestion?.question?.type === "OQ" ? (
                <div>
                    <PassOpenQuestionComponent question={currentQuestion} idProgression={idProgression}/>
                </div>) : (
                <div>
                    <PassClosedQuestionComponent question={currentQuestion} />
                </div>)}
        </div>

    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            setCurrentQuestion, setQuestions
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    listOfQuestions: state.examReducer.questionsToPass,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    examToPass: state.examReducer.examToPass,
    currentCourse: state.studentTraining.currentCourse,
    connectedUser: state.authReducer.connectedUser,
    isCompleted: state.studentTraining.isCompleted
});

export default connect(mapStateToProps, mapDispatchToProps)(PassExamComponent);

