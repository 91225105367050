import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../global-state/store/root-reducers';
import { useHistory } from 'react-router';
import { getExamToPass } from '../../../../global-state/actions/exam-actions';
import { createProgressExam, updateIndexProgress } from '../../../../global-state/actions/student-training-actions';
import { addLastPath, redirect, clearHistoryArray } from '../../../../global-state/actions/breadcrumb-actions';
import ExamResultsComponent from '../../../pass-exam/exam-results-component';
import { returnStartPathUrl } from '../../../../helpers/domainCheck';


enum Status {
    TODO,
    UNCOMPLETED,
    COMPLETED,
    TOREVIEW,
}
const SectionExamComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const [sectionContent, setSectionContent] = useState(props?.studentSection?.sectionContent)
    const history = useHistory();
    const [idExam, setIdExam] = useState<number>(sectionContent?.id)
    const [status, setStatus] = useState(props?.studentSection?.sectionContent?.progression?.status);
    const [examType, setExamType] = useState(props?.studentSection?.sectionContent?.qnaType);
    let dateFormat = require('dateformat');
    const [submissionDate, setSubmissionDate] = useState(props?.studentSection?.sectionContent?.progression?.updateDate)
    const [loader, setLoader] = useState<boolean>(false)


    useEffect(() => {
        setSectionContent(props?.studentSection?.sectionContent);
        setIdExam(props?.studentSection?.sectionContent?.id);
        if (props?.studentSection?.sectionContent?.progression) {
            switch (props?.studentSection?.sectionContent?.progression?.status) {
                case "UNCOMPLETED": setStatus(Status.UNCOMPLETED); break;
                case "COMPLETED": setStatus(Status.COMPLETED); break;
                case "TOREVIEW": setStatus(Status.TOREVIEW); break;
                default: setStatus(Status.TODO);
            }
            setSubmissionDate(props?.studentSection?.sectionContent?.progression?.updateDate)
        }
        else {
            setStatus(Status.TODO)
        }
    }, [props?.studentSection?.sectionContent])

    useEffect(() => {
        if (typeof props?.studentSection?.qnaType != undefined) {
            setExamType(props?.studentSection?.sectionContent?.qnaType)
        }

    }, [props?.studentSection])


    const startExam = async () => {
        setLoader(true)
        await props?.updateIndexProgress()
        await props?.clearHistoryArray();
        await props?.redirect(props?.currentCourse?.title, `${returnStartPathUrl()}/passCourse/course/${props?.currentCourse?.followUp?.id}`);
        await props?.redirect(sectionContent?.title, `${returnStartPathUrl()}/exam/pass/${idExam}`);
        
        if (props?.currentTraining?.type === 'PATH') {
            await props?.addLastPath(`${returnStartPathUrl()}/passPath/passCourse/${props?.currentCourse?.followUp?.id}`);
        }
        else if (props?.currentTraining?.type === 'SESSION') {
            await props?.addLastPath(`${returnStartPathUrl()}/passSession/passCourse/${props?.currentCourse?.followUp?.id}`);
        }
        else {
            await props?.addLastPath(`${returnStartPathUrl()}/passCourse/course/${props?.currentCourse?.followUp?.id}`);
        }


        if (props?.studentSection?.sectionContent?.progression === null) {
            await props?.createProgressExam({ id: null, entityType: props?.currentCourse?.type, entityId: props?.currentCourse?.id, idStudent: props?.userConnected?.idPerson, idQna: props?.studentSection?.sectionContent?.id, idFollow: props?.currentCourse?.followUp?.id })
            setStatus(Status.TODO)
        }
        history.push({ pathname: `${returnStartPathUrl()}/exam/pass/${idExam}` });
    }


    return (

        <div style={{ backgroundColor: "#FFFFFF" }}>
            {(status === Status.TODO || status === Status.UNCOMPLETED) && (
                <div className="d-flex flex-column align-items-center mx-4" style={{ backgroundColor: "#FBFBFD", border: '1px solid #F2F2F5', borderRadius: 3 }}>
                    <h3 className="H3-Headline" style={{ paddingTop: "41px" }}>{sectionContent?.title} </h3>
                    {(examType === "FINALEXAM") && (
                        <div className="my-3 mx-auto" style={{ textAlign: 'center' }}>
                            <span className="body-md neutral-2" style={{ paddingTop: "16px" }}>{t('Répondre_aux_questions_fermees_ou_ouvertes_en_respectant_les_consignes')} </span><br />
                            <span className="body-md neutral-2" style={{ paddingBottom: "24px" }} >{t('Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome')} <strong className="black-800">{t('Le_test_seffectue_une_seule_fois_et_détermine_lobtention_de_votre_diplome_2')}</strong></span>
                        </div>
                    )}
                    {(examType === "KNOWLEDGETEST") && (
                        <div className="my-3 mx-auto" style={{ maxWidth: 503, textAlign: 'center' }}>
                            <span className="body-md neutral-2"> {t("textExa")} </span>
                        </div>
                    )}
                    {!loader ? <div style={{ paddingBottom: "40px" }} >
                        {status === Status.TODO && (
                            <button type="button" className="btn-Primary large" onClick={() => { startExam() }} >{t("StartExam")}</button>
                        )}
                        {status === Status.UNCOMPLETED && (
                            <button type="button" className="btn-Primary large" onClick={() => { startExam() }} >{t("ReturnExam")}</button>
                        )}
                    </div>
                        :
                        <button className="btn-Primary loaddata large">
                            <div className="threedots-large-Animation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    }
                </div>
            )}
            {status === Status.TOREVIEW && (
                <>
                    <div className="grid-container mx-4" style={{ border: "1px solid #F2F2F5", borderRadius: "3px", padding: "40px 40px", backgroundColor: "#FBFBFD" }} >
                        <div className="d-flex align-items-center flex-wrap" >
                            <div className="test-state waiting d-flex align-items-center justify-content-center">
                                <div className="threedots-large-Animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <div style={{ paddingLeft: "70px" }}>
                                <div>
                                    <span className="H3-Headline black-800">{sectionContent?.title}</span>
                                </div>
                                <div className="mt-4">
                                    <span className="body-md bold neutral-3"> {t("Examen_final_envoyé_le")} <span style={{ color: "#272727" }}>{dateFormat(submissionDate, "dd/mm/yyyy")}</span></span>
                                </div>
                                <div className="mt-2 pt-1">
                                    <span className="body-md bold neutral-3"> {t("En_attende_de_correction_par_le_professeur")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {status === Status.COMPLETED && (
                <>
                    <ExamResultsComponent isMobile={props.isMobile} />
                </>
            )}
        </div>

    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getExamToPass,
            addLastPath,
            createProgressExam,
            updateIndexProgress,
            redirect,
            clearHistoryArray
        },
        dispatch
    );
const mapStateToProps = (state: AppState) => ({
    currentCourse: state.studentTraining.currentCourse,
    currentTraining: state.studentTraining.currentTraining,
    section: state.studentTraining.sectionContentDetails,
    userConnected: state.authReducer.connectedUser

});

export default connect(mapStateToProps, mapDispatchToProps)(SectionExamComponent);

