
import { FC, useEffect, useState, } from "react";
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AppState } from "../../../global-state/store/root-reducers";
import { resetPassword, checkPassword } from "../../../global-state/actions/auth-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import axiosInstance from "../../../helpers/interceptors";
import logoSchool from '../../../styles/imgs/koorsK.png'
import { returnStartPathUrl } from "../../../helpers/domainCheck";
interface RouteParams {
    token: string
}

const NewPassword: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { token } = useParams<RouteParams>();
    const [error, setError] = useState(undefined);
    let [showNP, SetShowNP] = useState(false);
    let [showNPClass, SetShowNPClass] = useState("");
    let [showNPIcon, SetShowNPIcon] = useState("");
    let [showCP, SetShowCP] = useState(false);
    let [msg, setMsg] = useState("");
    let [errorColor, setErrorColor] = useState("")
    let [newPass, setNewPass] = useState("")
    let [newPassError, setNewPassError] = useState("")
    let [newPassErrorSymbole, setNewPassErrorSymbole] = useState("")
    let [newPassErrorFigure, setNewPassErrorFigure] = useState("")
    let [newPassInvalid, setNewPassInvalid] = useState("")
    let [newPassClass, setNewPassClass] = useState("")
    let [confirmPass, setConfirmPass] = useState("")
    let [confirmPassClass, setConfirmPassClass] = useState("")
    let [showCPInput, setShowCPInput] = useState<any>()
    const [row, setRow] = useState<any>();
    let [fillColor, setFillColor] = useState("")
    let [fillColorFigure, setFillColorFigure] = useState("")
    let [fillColorMaj, setFillColorMaj] = useState("")
    let [fillColorSymbole, setFillColorSymbole] = useState("")
    let [showCPType, setShowCPType] = useState("");
    const regxpassword = /^.{8,}$/
    const regxSymbole = RegExp(/^(?=.*?[#?!=@$%^&*-]).+$/)
    const [userMail, setUserMail] = useState<string>("")
    useEffect(() => {
        if(token!=null && token!==undefined){
            getUserMailByToken()
        }
    }, [token])

        const getUserMailByToken = async () =>{
            await axiosInstance.get(process.env.REACT_APP_BASE_URL1 + "person/checkmail/forgetPassword?token=" + token)
                .then((response:any)=>{
                    setUserMail(response?.data)
                }).catch((err)=>{
                    history.push(`${returnStartPathUrl()}/invitation/expired`)
                })
        }


    useEffect(() => {
        let confirm = (confirmPass.length < 8 || confirmPass !== newPass) ? "form-control input text-default error" : "form-control input text-default success"
        setConfirmPassClass((confirmPass === '') ? "form-control input text-default" : confirm)

    }, [confirmPass, newPass])
    useEffect(() => {
        SetShowNPIcon(!showNP ? 'visibility_off' : 'visibility')
        SetShowNPClass(showNP ? "text" : "password")

    }, [showNP])

    useEffect(() => {
        setShowCPType(showCP ? "text" : "password")

    }, [showCP])


    useEffect(() => {
        let show=<span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => SetShowCP(!showCP)}>visibility</span>
        let showP=  (!showCP && confirmPass !== '') ? <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => SetShowCP(!showCP)}>visibility_off</span>
        :show
        let showM= (showCP && confirmPass === '') ? <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => SetShowCP(!showCP)}>visibility</span>
        :showP
        setShowCPInput((!showCP && confirmPass === '') ? <span className="material-icons" style={{ cursor: 'pointer', color: '#B1B1B1' }} onClick={() => SetShowCP(!showCP)}>visibility_off</span>
            : showM
                )

    }, [showCP, confirmPass])
    useEffect(() => {
        let minus =!(regxpassword.test(newPass)) ? "invalid-password" : "valid-password"
        let pass =(!regxpassword.test(newPass)) ? "form-control input text-default error " : "form-control input text-default success"
        setNewPassInvalid((newPass === '') ? "invalid-password" : minus)
        setNewPassClass((newPass === '') ? "form-control input text-default" :pass)
        let newP =(newPass.length < 8) ? "invalid-password" : "valid-password"
        setNewPassError((newPass === '') ? "invalid-password" :newP)
        setFillColor((newPass.length < 8) ? "#F2F2F5" : "#8DDBA9")
        setFillColorMaj(!(regxpassword.test(newPass)) ? "#F2F2F5" : "#8DDBA9")
        let regPass =!(regxpassword.test(newPass)) ? "invalid-password" : "valid-password"
        setNewPassErrorFigure((newPass === '') ? "invalid-password" : regPass)
        setFillColorFigure(!(regxpassword.test(newPass)) ? "#F2F2F5" : "#8DDBA9")
        let inv=!(regxSymbole.test(newPass)) ? "invalid-password" : "valid-password" 
        setNewPassErrorSymbole((newPass === '') ? "invalid-password" : inv)
        setFillColorSymbole(!(regxSymbole.test(newPass)) ? "#F2F2F5" : "#8DDBA9")
    }, [newPass])

    useEffect(() => {
        setErrorColor(((!showNP && newPass === '') || (showNP && newPass === '')) ? '#B1B1B1' : "#272727")
    }, [newPass, showNP])

    useEffect(() => {

        if (!!props?.password) {
            setRow(props?.password)
        }


        if (!!props?.error) {
            setError(t('invalidToken'))

        }
    }, [props?.password, props?.error])

    
    const action = async () => {


        await props?.resetPassword(userMail, confirmPass)

        await setMsg(props?.newPassword)
        setNewPass("");
        setConfirmPass("")
        await axiosInstance.delete(process.env.REACT_APP_BASE_URL1 + "person/deleteToken/" + token).then(()=>{
            history.push(`${returnStartPathUrl()}/`)
        })
    }


    return (
        < div style={{alignItems:"center" , display:"flex" , flexDirection:"column"}}>
            <div className="row" >
                <div style={{alignItems:"center" , display:"flex" , flexDirection:"column"}}>
                    <img style={{ height: 120 }} src={props?.logoschool?.logo === null ? logoSchool : props?.logoschool?.logo} alt="logo school" />
                </div>

            </div> 
            <div className="form-login row justify-content-center " >
                {
                    (error !== undefined) ?
                        <>
                            <div style={{ marginBottom: "30%" }}></div>
                            <div className="col-md-12 text-center">
                                <span className="H4-Subtitle ">  {t('invalidToken')}</span>

                                <div style={{ marginBottom: "2%" }}></div>
                                <div className="col-md-12 text-center">
                                    <div style={{ marginBottom: "5%" }}></div>
                                    <button type="button" className="btn-Secondary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                                        {t('cnxButton')}
                                    </button>

                                </div>
                            </div>

                        </>
                        :
                        <>
                            
                            <div className="col-md-12 text-center" style={{display:"flex" , flexDirection:"column" , paddingTop:"56px"}}>
                                <span className="H3-Headline mb-3">{t('recPass')}</span>
                                <span className='body-md ' style={{ alignItems:"center"}}>{t('yourAccount')} <span style={{fontWeight:"bold"}}>{userMail}</span></span>

                            </div>
                            {msg === "" &&
                                <>
                                    
                                    <div className="col-md-12" style={{paddingTop:"40px"}}>
                                        <div className="row justify-content-center">
                                            <div className="col-md-4 justify-content-center">


                                                <label className="form-title">{t('password')}  </label>
                                                <div className="input-form-control">

                                                    <input 
                                                        dir="ltr"
                                                        type={showNPClass} 
                                                        className={newPassClass} 
                                                        placeholder={t('newPass')} 
                                                        name="password" 
                                                        value={newPass} 
                                                        onChange={(e) => setNewPass(e.target.value)} 
                                                    />
                                                    <span className="material-icons" style={{ cursor: 'pointer', color: errorColor }} onClick={() => SetShowNP(!showNP)}>{showNPIcon}</span>

                                                </div>
                                                <div className="col-md-12 justify-content-center mt-3">
                                                    <label className="form-title">{t('passConfirmation')} </label>
                                                    <div className="input-form-control">

                                                        <input dir="ltr"type={showCPType} className={confirmPassClass} placeholder={t('confimrNewPass')} name="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                                                        {showCPInput}

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>



                                </>}
                            {msg !== "" &&
                                <>
                                    <div style={{ marginBottom: "5%" }}></div>
                                    <div className="col-md-12 text-center mt-4 pt-2">
                                        <span className="body-md">{t('msgUpdatedPass')}</span>

                                    </div>
                                </>
                            }

                            <div style={{ marginBottom: "2%" }}></div>

                            {msg?.length <= 0 &&
                                <>
                                    <div style={{ marginBottom: "2%" }}></div>
                                    <div className="col-md-12 text-center">
                                        <button type="button" className="btn-Primary large" disabled={!((regxpassword.test(newPass)) && newPass === confirmPass && userMail!=="" && userMail.length>0)} onClick={action}>
                                            {t('validPassButton')}
                                        </button>

                                    </div>
                                    <div className="col-md-12 text-center" style={{paddingTop:"56px ", paddingBottom:"32px"}}>
                                                <p className="link-text" style={{ textDecoration: 'none', cursor: 'pointer' }}>{t('alreadyHaveAcc')}</p>
                                            </div>
                                    <div className="col-md-12  text-center" style={{marginBottom:"89px"}} >
                                                <button type="button" className="btn-Secondary large"  onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)} style={{maxWidth:"283px" , maxHeight:"48px"}}>
                                                    <span >{t('cnxButton')}</span>
                                                    </button>
                                            </div>
                                </>
                            }
                            {msg?.length > 0 &&
                                <>
                                    <div style={{ marginBottom: "2%" }}></div>
                                    <div className="col-md-12 text-center">
                                        <span className="body-md">{t('validationNewPassword')}</span>

                                        <div style={{ marginBottom: "2%" }}></div>

                                        <button type="button" className="btn-Secondary large" onClick={() => history.push(`${returnStartPathUrl()}/auth/login`)}>
                                            {t('cnxButton')}
                                        </button>
                                    </div>

                                </>
                            }
                        </>
                }
            </div>
        </ div>
    )
}


const mapStateToProps = (state: AppState) => ({
    newPassword: state.authReducer.newPassword,
    password: state.authReducer.password,
    error: state.authReducer.error,
    logoschool: state.schoolInfoReducer.schoolInfo,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>

    bindActionCreators(
        {
            resetPassword,
            checkPassword
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);


