import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { getStudent } from '../../../global-state/actions/profile-actions';
import { setActualTab } from '../../../global-state/actions/professor-actions';
import { redirect, clearHistoryArray } from '../../../global-state/actions/breadcrumb-actions';
import { getStudentInfoById } from '../../../global-state/actions/course-actions';
import TableElement from '../../../elements/table/normal-table-element';
import { useHistory } from 'react-router-dom';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import TableAllStudentsWithAction from '../../../elements/table/table-all-students-with-action';
import StudentTableAction from '../../../elements/table-actions/student-table-action';


const StudentsGeneralInfoTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
  
    const { t } = useTranslation();
    const moment = require('moment');
    const history = useHistory();
    const [sizeGeneral, setSizeGeneral] = useState(20);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [allData, setAllData] = useState<any>(null);
    const [emptysearch, setemptysearch] = useState<any>(false);
    const [atBottom, setAtBottom] = useState<boolean>(false)
    const [studentDataToFormat, setStudentDataToFormat] = useState([])
    const [activateSnakbar, setActivateSnakbar] = useState<boolean>(false)
    const generalHeaders = [
        { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('Statut'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
    ]

    const activateSnakBar = ()=>{
        setActivateSnakbar(true)
    }

    useEffect(() => {
        if(props?.connectedUser?.role === 'role_prof' && props?.general !== undefined){
            setStudentDataToFormat(props?.general?.list)
        }else if (props?.connectedUser?.role === 'role_director' && props?.studentsList !== undefined){
            setStudentDataToFormat(props?.studentsList)
        }
        return () => {
            setStudentDataToFormat([])
        }
    }, [props?.studentsList, props?.general])

    const formatData =async (data:any) => {
        
        if(data === undefined){
            return;
        }
        const list = {
            headers: generalHeaders,
            body: data?.map((student: any) => {
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.fullName, classNames: "", actionName: '' },
                            { content: student.inscriptionDate, classNames: "text-center", actionName: '' },
                            {
                                content: student.lastConnectionDate,
                                classNames: `text-center`,
                                actionName: ''
                            },
                            {
                                content:(student?.studentStatus === "Enattente") ? < StudentTableAction id={student?.studentId} role={"STUDENT"} activateSnakBar={activateSnakBar} />   :((student?.studentStatus == "Admis") ? <span className='green-800'>{t('Admis')}</span> :<span className='red-800'>{t('Suspendu')}</span>),
                                classNames: `text-end`,
                                actionName: ''
                            }
                        ]
                    }
                }
            })
        }
        
        setAllData(list);
        setLoadingTable(false)
    }

    const formatProfeesorData =async (data:any) => {
        if(data === undefined){
            return;
        }
        const list = {
            headers: generalHeaders,
            body: data?.list?.map((student: any) => {
                return {
                    row: {
                        student: student,
                        actionName: "goToPath",
                        classNames: "table-white",
                        columns: [
                            { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                            { content: dateFormat(student?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                            {
                                content: (student?.lastConnexion === null) ? '-' : moment(student?.lastConnexion).fromNow(),
                                classNames: `text-center`,
                                actionName: ''
                            },
                            {
                                content:(student?.lastConnexion === null) ? <span style={{color:"#F5B95F"}}>{t('Enattente')}</span>  :((student?.statusStudent === "ADMIS") ? <span className='green-800'>{t('Admis')}</span> :<span className='red-800'>{t('Suspendu')}</span>),
                                classNames: `text-end`,
                                actionName: ''
                            }
                        ]
                    }
                }
            })
        }
        setAllData(list);
        setLoadingTable(false)
    }
    
    useEffect(() => {
        if (props?.connectedUser?.role === 'role_prof') {
            formatProfeesorData(props?.general)
        } else {
            formatData(props.studentsList)
        }
        setAtBottom(false)
    }, [studentDataToFormat])


    const dateFormat = require('dateformat');
    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
        
    }, []);

    useEffect(() => {
      
        if (!props?.data?.searchGeneral && props?.general?.list?.length === 0 && props?.followCount?.GENERAL > 0) 
        {
            setemptysearch(true)
        }else{
            setemptysearch(false)
        }
    }, [props?.data])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    useEffect(() => {
    if(props?.connectedUser?.role === 'role_director' ){
        setAtBottom(props?.loader)
    }
    }, [props?.loader])

    const handleScroll = async () => {
        if (!(atBottom) && ((window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight) && (allData?.body!==null && allData?.body !==undefined)&& ( allData?.body?.length < props?.followCount?.GENERAL) ) {
            setAtBottom(true);
            if( props?.connectedUser?.role === 'role_prof' ){
                await props.getNextPageGeneral()
            }else{
                await props?.getNextPage(props?.pageNumber+1)
            }
        }
        if (!(((window.innerHeight + window.scrollY) -5) < document.documentElement.scrollHeight) || (props?.connectedUser?.role === 'role_director'  && props?.loader===false )) {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        setSizeGeneral(props?.data?.sizeGeneral);
    }, [props?.data])

    const doAction = async (value: any) => {

        if (value.action === 'goToPath' && props?.connectedUser?.role === 'role_prof' ) {
            await props?.getStudentInfoById(value.row.student.id, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
    
            props?.clearHistoryArray();
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`);
            props?.redirect(value.row.student?.firstName + " " + value.row.student?.lastName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`);

             history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.id}`)
        }else if (value.action === 'goToPath' && props?.connectedUser?.role === 'role_director') {

            await props?.getStudentInfoById(value.row.student.studentId, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray();
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.fullName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.studentId}`)
            history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.studentId}`)
        }
    }
    return (
        <>
            {!loadingTable ?
                <div>
                    {(allData?.body?.length > 0) && <>
                        {(props?.connectedUser?.role === 'role_prof') ?
                            <TableElement data={allData} doAction={(value: any) => { doAction(value) }} />
                            :
                            <TableAllStudentsWithAction data={allData} doAction={(value: any) => { doAction(value) }} />
                        }
                        {(atBottom)
                            && <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>}
                    </>
                    }
                    {(allData?.body?.length === 0 ) &&
                        <div className="w-100 text-center mt-5 pt-5">
                            <EmptyListElement lines={[t('noStudentInscribed'), t('noStudentInscribed_line_2')]} />
                            <button type="button" className="btn-Primary large icon-left mx-auto" onClick={() => {props?.setModalOpened(true) ; props.setActualTabModal('one')}}>
                                {props?.connectuser?.role === "role_director" ||props?.connectuser?.role === "role_prof"  } <div className="d-flex align-items-center"><span className="material-icons">add</span>{t('addStudent')}<div className="m-auto ps-2 "></div></div>
                            </button>
                         </div>
                       
                    }
                </div> :
                <div>
                    <TableStudentsListLoadingComponent />
                </div>}
            <Snackbar open={activateSnakbar} autoHideDuration={6000} onClose={() => { setActivateSnakbar(!activateSnakbar) }}>
                <Alert style={{ backgroundColor: "#272727" }}>
                    {t('invitationSentSuccesfully')}
                </Alert>
            </Snackbar>
        </>
    );
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    listeStudents: state.profReducer.listeStudents,
    studentInfo: state.courseReducer.studentInfo,
    lastTabClicked: state.profReducer.lastTabClicked,
    loadStudentsGeneral: state.profReducer.loadStudentsGeneral,
    addDataList: ownProps?.addDataList,
    data: ownProps?.data,
    connectedUser: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudentInfoById,
            getStudent,
            setActualTab,
            redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentsGeneralInfoTableComponent);