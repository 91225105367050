import { ComponentProps, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/';
import "../../translations/i18n";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { setCurrentQuestion, submitCloseQuestionResponse, getExistingAnswers } from '../../global-state/actions/exam-actions';
import { AppState } from '../../global-state/store/root-reducers';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { updateCurrentProgressExam, calculateTraining, getCurrentCourse } from '../../global-state/actions/student-training-actions';
import { returnStartPathUrl } from '../../helpers/domainCheck';

interface Params {
    id: any
}
const PassClosedQuestionComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [question, setQuestion] = useState<any>();
    const { t } = useTranslation();
    const { id } = useParams<Params>();
    const [isAnswered, setIsAnswered] = useState(false);
    const [isResponseSelected, setIsResponseSelected] = useState(false);
    const history = useHistory();
    const [openQuestionForm, setOpenQuestionForm] = useState<any>()
    const [loader, setLoader] = useState<boolean>(false)
    const [numberOfResponses, setNumberOfResponses] = useState<number>(0)

    useEffect(() => {
        setQuestion(props?.question)
    }, [])

    useEffect(() => {
        setQuestion(props?.question)
    }, [props?.question])

    useEffect(() => {
        if (props?.progressTraining && props?.currentCourse?.followUp?.progressTraining && props?.currentCourse?.followUp?.progressTraining !== null && props?.currentCourse?.followUp?.progressTraining?.progressQna?.findIndex((obj: any) => props?.progressTraining?.id === obj?.id) !== -1) {
            props?.updateCurrentProgressExam(props?.progressTraining);
        }
    }, [props?.progressTraining])

    useEffect(() => {
        const liste = question?.question?.questionChoice?.map((questionTopass: any) => {
            return {
                id: null,
                responseChoice: questionTopass.choice,
                responseChoiceState: false,
            };
        });
        if (props?.studentAnswers && props?.studentAnswers[props?.indexQuestionToPass] && props?.studentAnswers[props?.indexQuestionToPass]?.id !== null) {
            const obj = { ...props?.studentAnswers[props?.indexQuestionToPass] };
            obj['questionByExamId'] = props?.question?.id;
            if (obj?.examId === undefined) {
                obj['examId'] = Number(id);
            }
            if (obj?.responseQuestionChoice === undefined || obj?.responseQuestionChoice?.length === 0) {
                const copy = { ...obj }

                obj['responseQuestionChoice'] = obj?.responseQuestionChoiceDtos;
                obj.responseQuestionChoiceDtos = undefined;
            }
            obj['studentId'] = props?.connectedUser?.idPerson;
            setOpenQuestionForm(obj);
            setIsAnswered(true);
        } else {
            setOpenQuestionForm({
                studentId: props?.connectedUser?.idPerson,
                questionByExamId: props?.question?.id,
                examId: Number(id),
                idProgressQna: props?.currentCourse?.exams?.find((obj: any) => obj?.id === Number(id))?.progression?.id,
                responseQuestionChoice: liste,
            })
            setIsAnswered(false);
        }
        setNumberOfResponses(calculateListOfResponse())
    }, [question, props?.studentAnswers])

    const verifyReponsesSelection = (response: any) => {

        for (let i = 0; i < response.length; i++) {
            if (response[i].responseChoiceState) {
                setIsResponseSelected(true);
                return true;
            }
        }
        setIsResponseSelected(false);
        return false;
    }

    const handleEvent = (position: number) => {

        let nextState = { ...openQuestionForm }
        if (question?.question?.quizType === "QCU") {
            nextState.responseQuestionChoice = nextState?.responseQuestionChoice?.map((questionResp: any, index: number) => {
                if (index === position) {
                    return {
                        id: questionResp.id,
                        responseChoice: questionResp.responseChoice,
                        responseChoiceState: true,
                    };
                } else {
                    return {
                        id: questionResp.id,
                        responseChoice: questionResp.responseChoice,
                        responseChoiceState: false,
                    };
                }

            });
        } else {
            nextState.responseQuestionChoice[position].responseChoiceState = !nextState.responseQuestionChoice[position]?.responseChoiceState;
        }

        verifyReponsesSelection(nextState.responseQuestionChoice)
        setOpenQuestionForm(nextState);

    };

    const submitResponse = async () => {
        setLoader(true)
        setIsAnswered(true)
        const copy = { ...openQuestionForm };
        copy['responseQuestionChoiceDtos'] = copy.responseQuestionChoice;
        copy['idProgressQna'] = props?.currentCourse?.exams?.find((obj: any) => obj?.id === Number(id))?.progression?.id;
        copy.responseQuestionChoice = undefined;
        await props?.submitCloseQuestionResponse(copy, props?.indexQuestionToPass, props?.currentCourse?.followUp?.progressTraining, props?.currentCourse);
        if (props?.currentCourse?.type !== "COURSE") {
            await props?.calculateTraining(props?.currentCourse, props?.currentTraining, props?.listFollowUps, props?.currentProgress)
        }
        await props?.getExistingAnswers(id, props?.connectedUser?.idPerson, props?.currentCourse?.followUp?.id);
        await postVAlid()
        setLoader(false)
    }

    const redirectAfterFinishingExam = async () =>{
        await props?.getCurrentCourse(props?.currentCourse?.followUp?.id);
        if (props?.currentCourse?.type === 'PATH') {
            history.push(`${returnStartPathUrl()}/passPath/progressPath/${props?.currentCourse?.followUp?.id}`);
        } else if (props?.currentCourse?.type === 'SESSION') {
            history.push(`${returnStartPathUrl()}/passSession/progressSession/${props?.currentCourse?.followUp?.id}`);
        } else {
            if (props?.currentTraining?.type === 'PATH') {
                history.push(`${returnStartPathUrl()}/passPath/passCourse/${props?.currentCourse?.followUp?.id}`);
            } else if (props?.currentTraining?.type === 'SESSION') {
                history.push(`${returnStartPathUrl()}/passSession/passCourse/${props?.currentCourse?.followUp?.id}`);
            } else {
                history.push(`${returnStartPathUrl()}/passCourse/course/${props?.currentCourse?.followUp?.id}`);
            }
        }
    }

    useEffect(()=>{
        if ((numberOfResponses === props?.studentAnswers?.length) && (Number(id) === props?.exam?.id)){
            redirectAfterFinishingExam()
        }
    },[numberOfResponses])
    
    
    const postVAlid = async () => {
        if (props?.indexQuestionToPass < props?.exam?.questionByQnaList.length - 1) {
            props?.setCurrentQuestion(props?.indexQuestionToPass + 1);
            setIsAnswered(false)
        } 
    };

    const calculateListOfResponse = () =>{
        let numberOfAnswerQuestion = 0 
        for (let i=0 ; i< props?.studentAnswers?.length ; i++ ){
            if (props?.studentAnswers[i]?.id !== null){
                numberOfAnswerQuestion++
            }
        }
        return numberOfAnswerQuestion
    }

    return (
        <div>
            <div className="d-flex flex-column">
                {openQuestionForm?.responseQuestionChoice?.map((item: any, index: number) => {
                    return (
                        <div key={index} className={`${(isAnswered) ? 'check-checkbox p-0' : ''} d-flex flex-row justify-content-center`}>
                            <input dir="ltr" disabled={isAnswered} readOnly={true} checked={item?.responseChoiceState} className="input-style-checkbox-response" type="checkbox" name={(question?.question?.quizType === "QCU") ? "exampleradio2" : "exampleradio" + index} id={"outlinedradiobutton" + index} value="option1"
                                style={{ border: (item?.responseChoiceState) ? "1px solid #6089D2" : "1px solid #DFDFDF" }} />
                            <label style={{ textTransform: "none", cursor: (isAnswered) ? 'default' : 'pointer' }} htmlFor={"outlinedradiobutton" + index} onClick={() => (!isAnswered) ? handleEvent(index) : {}} >
                                <div className="d-flex flex-column align-items-center justify-content-center" >
                                    <div className="d-flex">
                                        <span className="H4-Subtitle mb-2" > {t('Réponse')} {index + 1}</span>
                                    </div>
                                    <div className="d-flex">
                                        <span className="text-break body-md neutral-2 text-center" >{item?.responseChoice}</span>
                                    </div>
                                </div>
                            </label>
                        </div>
                    )
                })}
            </div>
            <div>
                <div className="row " style={{ margin: "32px 0px 0px 0px " }}  >
                    {!loader ? <button disabled={isAnswered || loader} className="btn-Primary large py-4" onClick={() => { submitResponse() }}> {t("Valider_ma_réponse")} </button>
                        :
                        <button className="btn-Primary large w-100 py-4">
                            <div className="threedots-large-Animation ">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            submitCloseQuestionResponse,
            setCurrentQuestion,
            updateCurrentProgressExam,
            calculateTraining,
            getCurrentCourse,
            getExistingAnswers
        },
        dispatch
    );
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    question: ownProps?.question,
    indexQuestionToPass: state.examReducer.indexQuestionToPass,
    exam: state.examReducer.examToPass,
    studentAnswers: state.examReducer.listOfResponses,
    currentCourse: state.studentTraining.currentCourse,
    currentTraining: state.studentTraining.currentTraining,
    listFollowUps: state.studentTraining.listFollowUps,
    currentProgress: state.studentTraining.currentTrainingProgress,
    sectionContentDetails: state.studentTraining.sectionContentDetails,
    progressTraining: state.examReducer.progressTraining
});

export default connect(mapStateToProps, mapDispatchToProps)(PassClosedQuestionComponent);